<template>
  <div class="pt-16">
    <div
      class="w-full h-full bg-fixed bg-contain md:bg-center bg-no-repeat"
      :style="`background-image: url(${bgImage})`"
    >
      <div
        class="w-full h-75vh md:h-50vh lg:h-75vh py-12 px-4 md:px-0 flex items-center"
      >
        <div class="w-full bg-softGrey80">
          <p
            class="md:w-768 lg:w-1024 m-auto p-4 text-xl text-pulpBlue text-center font-semibold"
          >
            Better English means a better life!​<br />
            neo will help you reach the English goals established by your school
            or university​ quickly and effectively regardless of your age and
            fluency level. ​
          </p>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Begin Your English Journey with neoAssess
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              Your school or university will set a time and place for you to
              take neoAssess for placement. This adaptive test should take 20
              minutes to complete, less if you are just starting your English
              journey. The test will stop when neo determines your current
              English proficiency. You will be placed in neoStudy at the next
              CEFR level.
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-40 lg:h-52"
                src="@/assets/images/icons/A.-NEOSTUDY.png"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  Study at your own pace with neoStudy​
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  You will be set up with a login and password for neoStudy.
                  neoStudy allows you to study in short lesson steps, earning
                  points as you complete exercises. It is available online and
                  as an app for your mobile devices, enabling you to study both
                  at school and at home. Your records remain synchronized at all
                  times.​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Conversational English Skills​
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              In addition to your independent practice with neoStudy, you will
              also meet with your coach or teacher (online or in-class) to
              practice your verbal communication skills and address any
              questions you had during your independent study. Study up to an
              hour at a time and then take a break. If you study continuously,
              you will earn fewer points, so study in short sessions and take
              breaks to keep your brain alert.​​
            </p>
          </div>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-52 lg:h-72"
                src="@/assets/images/icons/C.-Youngboywithcert.jpg"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  Earn certificates that show your English ability​
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  As you study with neo, you will complete the study
                  requirements for every Common European Framework of Reference
                  for Languages (CEFR) level before you can continue with the
                  next level. You will also complete a variety of Mastery Tests,
                  the sum of these progressive tests adds up to all the
                  CEFR-level requirements. When you are ready, neo will prompt
                  you to take the Certification Test for your current level.
                  When you pass the test, you will be awarded an internationally
                  accepted English-language certificate.<br /><br />
                  Your certificate’s validity can be verified
                  <router-link
                    to="/studentcertificateverification"
                    class="text-secondary"
                    >online</router-link
                  >. Test it now.​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-16">
        <AboutNeoCourses />
      </div>
    </div>
    <!-- <ul class="mb-20 flex justify-center">
      <li
        class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
      >
        Download more info
      </li>
    </ul> -->
  </div>
</template>

<script>
import AboutNeoCourses from "../components/AboutNeoCourses.vue";

export default {
  components: {
    AboutNeoCourses,
  },
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/13.-student-school-university.jpg"),
      isMobile: window.innerWidth <= 768,
      mobileImageUrl: require("@/assets/images/mobile/14.-young-studnet-720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/13.-student-school-university.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>